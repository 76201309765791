// 获取列表
const getListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/pointlog`
// 导出列表
const exportListURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/pointlog/export`
// 获取巡更路线
const getPatrolRouteURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/route/select2`
// 获取详情
const getPatrolLogURL = `${API_CONFIG.butlerBaseURL}electronicPatrol/pointlog/`

// 根据id判断是否是老工单
const checkIsOldOrder = `${API_CONFIG.butlerBaseURL}butler/operateOrder/checkIsOldOrder`

export {
  getListURL,
  exportListURL,
  getPatrolRouteURL,
  getPatrolLogURL,
  checkIsOldOrder
}
