<template>
  <div class="routeLog-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :extraParams="extraParams"
      :headers="headers"
      exportMethod="delay"
    >
      <template #searchSlot>
        <v-input label="巡更点名称" v-model="searchParams.name"></v-input>
        <v-select label="巡更方式" v-model="searchParams.type" :options="typeOps"></v-select>
        <v-input label="设备编号" v-model="searchParams.bluetoothNum"></v-input>
        <v-input label="设备位置" v-model="searchParams.bluetoothLocation"></v-input>
        <v-select2 label="所属巡更路线" v-model="searchParams.routeId" v-bind="patrolRouteParams" @onChange="routeChange"></v-select2>
        <v-select2 label="所属项目" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="所属组团" v-model="searchParams.blockName"></v-input>
        <v-input label="巡更人员" v-model="searchParams.dealUserName"></v-input>
        <v-select label="巡更状态" v-model="searchParams.status" :options="statusOps"></v-select>
        <v-datepicker label="巡更时间段" :startTime.sync="searchParams.startTime" :endTime.sync="searchParams.endTime"></v-datepicker>
        <v-datepicker label="计划完成时间" :startTime.sync="searchParams.planFinishStartTime" :endTime.sync="searchParams.planFinishEndTime"></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" permission="detail" type="text" @click="checkDeatail(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getListURL, exportListURL, getPatrolRouteURL } from './api'
import { typeOps, typeMap, statusOps, statusMap } from './map'
import { communityParams } from './../map'

export default {
  name: 'routeLog',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      searchParams: {
        name: '',
        type: null,
        bluetoothNum: '',
        bluetoothLocation: '',
        routeId: '',
        communityId: '',
        blockName: '',
        dealUserName: '',
        status: null,
        startTime: '',
        endTime: '',
        planFinishStartTime: '',
        planFinishEndTime: ''

      },
      extraParams: {},
      communityParams: communityParams,
      patrolRouteParams: {
        searchUrl: getPatrolRouteURL,
        request: {
          value: 'routeId',
          text: 'name'
        }
      },
      statusOps: statusOps,
      typeOps: typeOps,
      headers: [
        {
          prop: 'pointName',
          label: '巡更点名称'
        },
        {
          prop: 'firstLevelName',
          label: '一级分类'
        },
        {
          prop: 'secondLevelName',
          label: '二级分类'
        },
        {
          prop: 'type',
          label: '巡更方式',
          // TODO 待修改
          formatter: (val) => {
            let type = val.type || 0
            return typeMap[type]
          }
        },
        {
          prop: 'routeName',
          label: '所属巡更路线'
        },
        {
          prop: 'bluetoothNum',
          label: '设备编号'
        },
        {
          prop: 'bluetoothLocation',
          label: '设备位置'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockname',
          label: '所属组团'
        },
        {
          prop: 'dealUserName',
          label: '巡更人员'
        },
        {
          prop: 'status',
          label: '巡更状态',
          formatter: (val) => statusMap[val.appStatus]
        },
        {
          prop: 'uploadTime',
          label: '上传时间'
        },
        {
          prop: 'finishTime',
          label: '巡更时间'
        }
      ]
    }
  },
  created () {
    if (this.$route.query.routeId) {
      this.extraParams.routeId = this.$route.query.routeId
    }
    if (this.$route.query.routeLogId) {
      this.extraParams.routeLogId = this.$route.query.routeLogId
    }
  },
  methods: {
    checkDeatail (row) {
      this.$router.push({
        name: 'patrolSignForm',
        query: {
          id: row.id
        }
      })
    },
    routeChange (val,flag) {
      if(!flag) {
        if (this.searchParams.routeLogId) {
          delete this.searchParams.routeLogId
        }
      }    
    }
  }
}
</script>
